<template>
  <Card class="joint-punishment-form">
    <Form ref="dataForm" style="max-width:800px" :model="formItem" :rules="ruleValidate" :label-width="240">
      <Form-item label='区域' prop="Region">
        <city-select-list :city="formItem.Region" @selectCity="selectCity"></city-select-list>
      </Form-item>
      <Form-item label="惩戒对象" prop="Subject">
        <Input v-model="formItem.Subject" placeholder="请输入惩戒对象"></Input>
      </Form-item>
      <Form-item label="统一社会信用代码或工商注册号" prop="SubjectBusinessLicense">
        <Input v-model="formItem.SubjectBusinessLicense" placeholder="请输入统一社会信用代码或工商注册号"></Input>
      </Form-item>
      <Form-item label="法人代表姓名" prop="SubjectLegalPersonName">
        <Input v-model="formItem.SubjectLegalPersonName" placeholder="请输入法人代表姓名"></Input>
      </Form-item>
      <Form-item label="法定代表人或自然人身份证件号码" prop="SubjectLegalPersonID">
        <Input v-model="formItem.SubjectLegalPersonID" placeholder="请输入法定代表人或自然人身份证件号码"></Input>
      </Form-item>
      <Form-item label="列入名单事由" prop="Reason">
        <Input type="textarea" v-model="formItem.Reason" :autosize="{minRows: 2,maxRows: 8}" placeholder="请输入违法内容"></Input>
      </Form-item>
      <Form-item label="涉及金额" prop="Amount">
        <Input v-model="formItem.Amount" placeholder="请输入"></Input>
      </Form-item>
      <Form-item label="列入日期" prop="EstDate">
        <DatePicker v-model="formItem.EstDate" type="date" placeholder="请选择" style="width: 200px"></DatePicker>
      </Form-item>
      <Form-item label="认定部门" prop="SupervisorName">
        <Input v-model="formItem.SupervisorName" placeholder="请输入认定部门"></Input>
      </Form-item>
      <Form-item label="文书号" prop="AdjudicationNo">
        <Input v-model="formItem.AdjudicationNo" placeholder="请输入文书号"></Input>
      </Form-item>
      <Form-item label="退出名单原因" prop="QuitReason">
        <Input type="textarea" v-model="formItem.QuitReason" :autosize="{minRows: 2,maxRows: 8}" placeholder="请输入退出名单原因"></Input>
      </Form-item>
      <Form-item label="退出日期" prop="QuitDate">
        <DatePicker v-model="formItem.QuitDate" type="date" placeholder="请选择" style="width: 200px"></DatePicker>
      </Form-item>
      <FormItem>
        <Button type="primary" size="large" long :loading="submitting" @click="submitFrom">{{ editMode?'提交修改':'提交新增' }}</Button>
      </FormItem>
    </Form>
  </Card>
</template>

<script>
import citySelectList from '../public/CitySelectList'
import leftPad from 'leftpad'
export default {
  name: 'joint-punishment-form',
  components: {
    citySelectList,
  },
  props: {
    data: {
      type: Object,
      default: () => null
    }
  },
  watch: {
    data: function (newVal) {
      if (newVal) {
        Object.assign(this.formItem, newVal)
        this.editMode = true
      } else {
        this.editMode = false
        this.formItem = {
          Subject: null,
          SubjectLegalPersonName: null,
          SubjectBusinessLicense: null,
          SubjectLegalPersonID: null,
          Reason: null,
          Amount: null,
          EstDate: null,
          AdjudicationNo: null,
          QuitReason: null,
          QuitDate: null,
          Region: null,
          Province: null,
          Municipality: null,
          County: null,
          SupervisorName: null,
          Operator: null
        }
      }
    },
  },
  data () {
    return {
      visible: false,
      editMode: false,
      formItem: {
        Tid: null,
        Subject: null,
        SubjectLegalPersonName: null,
        SubjectBusinessLicense: null,
        SubjectLegalPersonID: null,
        Reason: null,
        Amount: null,
        EstDate: null,
        AdjudicationNo: null,
        QuitReason: null,
        QuitDate: null,
        Region: null,
        Province: null,
        Municipality: null,
        County: null,
        SupervisorName: null,
        Operator: null
      },
      ruleValidate: {
        Subject: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        SubjectLegalPersonName: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        SubjectBusinessLicense: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        EstDate: [
          { required: true, type: 'date', message: '不能为空', trigger: 'blur' }
        ],
        Reason: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
        Amount: [
          { required: true, type: 'number', message: '不能为空', trigger: 'blur' },
        ],
        AdjudicationNo: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
        SupervisorName: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
        Region: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
      },
      submitEnabled: true,
      submitting: false
    }
  },
  mounted () {
    if (this.$store.getters.name) {
      this.formItem.Operator = this.$store.getters.name
    }
    let curItem = localStorage.getItem('joint-punishment-current')
    if (curItem) {
      curItem = JSON.parse(curItem)
    }
    if (curItem && curItem.Tid && curItem.Tid.toString() === this.$route.params.tid) {
      this.formItem = curItem
      this.editMode = true
      if (curItem.Province > 0) {
        this.getCityCode(curItem.Province, curItem.Municipality, curItem.County)
      }
    }
  },
  methods: {
    selectCity (value) {
      this.formItem.Region = value
      if (value.length > 0) {
        this.formItem.Province = parseInt(value.substr(0, 2))
        this.formItem.Municipality = parseInt(value.substr(2, 2))
        this.formItem.County = parseInt(value.substr(4, 2))
      } else {
        this.formItem.Province = 0
        this.formItem.Municipality = 0
        this.formItem.County = 0
      }
    },
    getCityCode (province, municipality, county) {
      this.formItem.Region = leftPad(province, 2) +
        leftPad(municipality, 2) +
        leftPad(county, 2)
    },
    submitFrom () {
      if (this.editMode) {
        return this.edit()
      } else {
        return this.create()
      }
    },
    create () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.submitting = true
          this.submitEnabled = false
          this.$Spin.show()
          let model = {}
          Object.assign(model, this.formItem)
          model.Amount *= 100
          this.$Api.Project.addJointPunishment(model).then(() => {
            this.$Message.success('操作成功！!')
            this.$Spin.hide()
            this.submitEnabled = true
            this.submitting = false
            this.formItem = {
              Subject: null,
              SubjectLegalPersonName: null,
              SubjectBusinessLicense: null,
              SubjectLegalPersonID: null,
              Reason: null,
              Amount: null,
              EstDate: null,
              AdjudicationNo: null,
              QuitReason: null,
              QuitDate: null,
              Region: null,
              Province: null,
              Municipality: null,
              County: null,
              SupervisorName: null,
              Operator: null
            }
          }).catch(err => {
            this.$Spin.hide()
            this.$Message.error('提交失败！!' + err.message)
            this.submitEnabled = true
            this.submitting = false
          })
        } else {
          this.$Message.error('表单验证失败!')
          this.submitEnabled = true
          this.submitting = false
        }
      })
    },
    edit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.submitting = true
          this.submitEnabled = false
          this.$Spin.show()
          let model = {}
          Object.assign(model, this.formItem)
          model.Amount *= 100
          this.$Api.Project.editJointPunishment(model).then(() => {
            this.$Message.success('操作成功！!')
            this.$Spin.hide()
            this.submitEnabled = true
            this.submitting = false
          }).catch(err => {
            this.$Spin.hide()
            this.$Message.error('提交失败！!' + err.message)
            this.submitEnabled = true
            this.submitting = false
          })
        } else {
          this.$Message.error('表单验证失败!')
          this.submitEnabled = true
          this.submitting = false
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
